import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import common from '@/common'
import bedExitMonitor from './routes/bedExitMonitor'
import callBell from './routes/callBell'
import geoFencing from './routes/geoFencing'
import _demos from './routes/_demos'
import kiosk from './routes/kiosk'

// add _demo pages in development mode
let demos = []
if (process.env.NODE_ENV === 'development' || process.env.VUE_APP_API_ENV === 'Dev') {
  demos = _demos
}

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  },
  routes: [
    ...bedExitMonitor,
    ...callBell,
    ...geoFencing,
    ...demos,
    ...kiosk,
    {
      name: 'home',
      path: '/',
      redirect: 'login'
    },
    {
      name: 'login',
      path: '/login',
      component: () => import('../views/Login.vue'),
      meta: {
        layout: 'full',
        auth: false,
      }
    },
    {
      name: 'todo',
      path: '/todo',
      component: () => import('../views/Todo.vue')
    },
    {
      name: 'company',
      path: '/company',
      component: () => import('../views/company/Index.vue'),
      meta: {
        keepAlive: true,
      }
    },
    {
      name: 'company_add',
      path: '/company/add',
      component: () => import('../views/company/Add.vue')
    },
    {
      name: 'company_edit',
      path: '/company/edit/:id',
      component: () => import('../views/company/Edit.vue')
    },
    {
      name: 'facility',
      path: '/facility',
      component: () => import('../views/facility/Index.vue'),
      meta: {
        keepAlive: true,
      }
    },
    {
      name: 'facility_add',
      path: '/facility/add',
      component: () => import('../views/facility/Add.vue')
    },
    {
      name: 'facility_edit',
      path: '/facility/edit/:id',
      component: () => import('../views/facility/Edit.vue')
    },
    {
      name: 'staff',
      path: '/staff',
      component: () => import('../views/staff/Index.vue'),
      meta: {
        keepAlive: true,
      }
    },
    {
      name: 'staff_add',
      path: '/staff/add',
      component: () => import('../views/staff/Add.vue')
    },
    {
      name: 'staff_edit',
      path: '/staff/edit/:id',
      component: () => import('../views/staff/Edit.vue')
    },
    {
      name: 'nfc',
      path: '/nfc',
      component: () => import('../views/nfc/Index.vue'),
      meta: {
        keepAlive: true,
        // hideInNodeEnvProduction: true,
      }
    },
    {
      name: 'bedfloor',
      path: '/floor',
      component: () => import('../views/floor/Index.vue'),
      meta: {
        keepAlive: true,
      }
    },
    {
      name: 'bedfloor_add',
      path: '/floor/add',
      component: () => import('../views/floor/Add.vue')
    },
    {
      name: 'bedfloor_edit',
      path: '/floor/edit/:id',
      component: () => import('../views/floor/Edit.vue')
    },
    {
      name: 'bedtype',
      path: '/bedtype',
      component: () => import('../views/bedType/Index.vue'),
      meta: {
        keepAlive: true,
      }
    },
    {
      name: 'bedtype_add',
      path: '/bedtype/add',
      component: () => import('../views/bedType/Add.vue')
    },
    {
      name: 'bedtype_edit',
      path: '/bedtype/edit/:id',
      component: () => import('../views/bedType/Edit.vue')
    },
    {
      name: 'bed',
      path: '/bed',
      component: () => import('../views/bed/Index.vue'),
      meta: {
        keepAlive: true,
      }
    },
    {
      name: 'bed_add',
      path: '/bed/add',
      component: () => import('../views/bed/Add.vue')
    },
    {
      name: 'bed_edit',
      path: '/bed/edit/:id',
      component: () => import('../views/bed/Edit.vue')
    },
    {
      name: 'member',
      path: '/member',
      component: () => import('../views/member/Index.vue'),
      meta: {
        keepAlive: true,
      }
    },
    {
      name: 'member_add',
      path: '/member/add',
      component: () => import('../views/member/Add.vue')
    },
    {
      name: 'member_edit',
      path: '/member/edit/:id',
      component: () => import('../views/member/Edit.vue')
    },
    {
      name: 'group',
      path: '/group',
      component: () => import('../views/group/Index.vue'),
      meta: {
        keepAlive: true,
      }
    },
    {
      name: 'group_add',
      path: '/group/add',
      component: () => import('../views/group/Add.vue')
    },
    {
      name: 'group_edit',
      path: '/group/edit/:id',
      component: () => import('../views/group/Edit.vue')
    },
    {
      name: 'role',
      path: '/role',
      component: () => import('../views/role/Index.vue'),
      meta: {
        keepAlive: true,
      }
    },
    {
      name: 'role_add',
      path: '/role/add',
      component: () => import('../views/role/Add.vue')
    },
    {
      name: 'role_edit',
      path: '/role/edit/:id',
      component: () => import('../views/role/Edit.vue')
    },
    {
      name: 'notice',
      path: '/notice',
      component: () => import('../views/notice/Index'),
      meta: {
        keepAlive: true,
      }
    },
    {
      name: 'admin',
      path: '/admin',
      component: () => import('../views/admin/Index'),
      meta: {
        keepAlive: true,
      }
    },
    {
      name: 'admin_add',
      path: '/admin/add',
      component: () => import('../views/admin/Add')
    },
    {
      name: 'admin_edit',
      path: '/admin/edit/:id',
      component: () => import('../views/admin/Edit')
    },
    {
      name: 'error-404',
      path: '/error-404',
      component: () => import('../views/error/Error404'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        action: 'read',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ]
})

router.beforeEach((to, from, next) => {
  if (to.meta?.keepAlive) {
    store.commit('app/ADD_CACHED_PAGE', to.name)
  }

  let cache_deploy_version = Number(localStorage.getItem('deploy_version'))
  cache_deploy_version = cache_deploy_version ? cache_deploy_version : 0
  if (to.meta.isStandalone) {
    next()
  } else if (to.name !== 'login' && (cache_deploy_version < common.config.deploy_version)) {
    common.logout()
    return false
  } else if (to.meta.auth !== false && !common.getAccessToken()) {
    next({name: 'login'})
  } else if (to.path === '/') {
    next({name: common.getMenuFirstRoute()})
  } else if (to.meta.hideInNodeEnvProduction) {
    if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_API_ENV !== 'Mock') {
      next({name: 'error-404'})
    } else {
      common.showToast({
        title: 'Dev Info',
        text: `"${to.name}" is under development`,
        variant: 'info',
        icon: 'CpuIcon'
      })
      next()
    }
  } else {
    next()
  }
})

export default router
