export default [
  {
    name: 'bedexitenvironment',
    path: '/deviceEnv',
    component: () => import('@/views/bedExitMonitor/deviceEnv/Index'),
    meta: {
      keepAlive: true,
    },
  },
  {
    name: 'bedexitenvironment_add',
    path: '/deviceEnv/add',
    component: () => import('@/views/bedExitMonitor/deviceEnv/Add')
  },
  {
    name: 'bedexitenvironment_edit',
    path: '/deviceEnv/edit/:mac/:env',
    component: () => import('@/views/bedExitMonitor/deviceEnv/Edit')
  },
  {
    name: 'bedexitfacility',
    path: '/deviceManage',
    component: () => import('@/views/bedExitMonitor/deviceManage/Index'),
    meta: {
      keepAlive: true,
    },
  },
  {
    name: 'bedexitfacility_edit',
    path: '/deviceManage/edit/:id',
    component: () => import('@/views/bedExitMonitor/deviceManage/Edit')
  },
  {
    name: 'bedexitview',
    path: '/bedexitview',
    component: () => import('@/views/bedExitMonitor/deviceView/Index.vue'),
    meta: {
      keepAlive: true,
    },
  },
  {
    name: 'bedexitview_person',
    path: '/bedexitview/person/:id',
    component: () => import('@/views/bedExitMonitor/deviceView/PersonRecord.vue')
  },
  {
    name: 'bedexitreset',
    path: '/deviceReset',
    component: () => import('@/views/bedExitMonitor/deviceReset/Index'),
    meta: {
      keepAlive: true,
    },
  },
  {
    name: 'bedexitreport',
    path: '/deviceReport',
    component: () => import('@/views/bedExitMonitor/deviceReport/Index'),
    meta: {
      keepAlive: true,
    },
  },
  {
    name: 'bedexitbatchupload',
    path: '/deviceBatchUpload',
    component: () => import('@/views/bedExitMonitor/deviceBatchUpload/Index')
  },
  {
    name: 'bedexitrawdata',
    path: '/deviceRawData',
    component: () => import('@/views/bedExitMonitor/deviceRawData/Index'),
    meta: {
      keepAlive: true,
    },
  }
]
