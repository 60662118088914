import mocks from '@/@fake-db/mockAdapters'
import samples from '@/@fake-db/responseSamples'
import dbFunctions from '@/@fake-db/dbFunctions'

// A package for generating mock data (https://github.com/nuysoft/Mock/wiki/Syntax-Specification)
import Mock from 'mockjs'

const ApiMap = {
  '/member/GetMemberStatusDropDownList': (config) => {
    const template = {
      "return_code": 0,
      "result_msg": "Success",
      "data": {
        "status": [
          {
            "statusCode": "MSF",
            "statusDesc": "在院舍"
          },
          {
            "statusCode": "MSBH",
            "statusDesc": "在家中"
          },
          {
            "statusCode": "MSIH",
            "statusDesc": "在醫院"
          },
          {
            "statusCode": "MSE",
            "statusDesc": "已退出"
          }
        ]
      }
    }
    return [200, Mock.mock(template)]
  },
  '/member/GetMemberList': (config) => {
    const total = 124
    const { pageIndex = 0, pageSize = 99 } = dbFunctions.getSearchParams(config)
    const currentPageSize = dbFunctions.getCurrentPageSize({ total, pageIndex, pageSize })
    const template = {
      'return_code': 0,
      'result_msg': 'Success',
      'data': { total, pageIndex, pageSize },
    }
    let i = pageIndex * pageSize + 1
    template.data[`members|${currentPageSize}`] = [{
      'qrCode|1': [/[A-F0-9]{128}/, /[A-F0-9]{128}/, null],
      'externalQrCodes': () => Mock.mock({
        'qrCode|0-4': [/^[A-Z0-9]{36}$/],
      }).qrCode.join(','),
      'floorName': '樓層1',
      'roomName': '房間1',
      'photo|1': ["files/MemberPhoto/MemberPhoto_29_20220511171624975.png", "files/MemberPhoto/MemberPhoto_29_20220512181701530.png" ,"files/MemberPhoto/MemberPhoto_29_20220529183722477.jpg"],
      'status|1': ['MSF', 'MSBH', 'MSIH', 'MSE'],
      'memberID': () => i,
      'memberCode': () => `Member-${i}`,
      'chineseName': '@CNAME',
      'englishName': '@NAME',
      'bedNumber': function () {
        return `101-${i++}`
      },
      "hkid": null,
      "memberName": '@CNAME',
      "memberBirth": null,
      "age": /[4-9]\d/,
      "isMale|1": true,
    }]
    return [200, Mock.mock(template)]
  },
  '/member/GetMember': (config) => {
    const template = {
      "return_code": 0,
      "result_msg": "Success",
      "data": {
        "member": {
          "companyID": 1,
          "facilityID": 11,
          "floorID": 1,
          "roomID": 1,
          "bedID": 1,
          "memberCode": 'Member-01',
          "refCode": 'A123',
          "chineseName": "阿朱",
          "englishName": "Arjuna",
          "hkid": '123456',
          "emergencyPerson": '阿達',
          "emergencyPhone": '12345678',
          "isMale": false,
          "birthDate": '1960-01-01',
          "joinedDate": '2020-01-01',
          "status": "MSF",
          "nfcTags": [
            "FD926AB53910CE1",
            "FD926AB53910CE2",
            "BCF26AB539EB328",
          ],
          "externalQrCodes": [
            {
              "qrCode": "5DADA8D5F7DEC7BFC8D7C48CBEF4B0B5",
              "organId": 1
            },
            {
              "qrCode": "645AACC8FBC87BA63583E8B627C85E46",
              "organId": 2
            }
          ],
          "qrCode": "B3461C9172AE3CD8AFC58DC8B0CEB7EC",
          "rfidTags": [
            "A02226000003A02226000002",
            "A02226000003A02226000003",
            "A02226000003A02226000004"
          ],
        }
      }
    }
    return [200, Mock.mock(template)]
  },
  '/member/AddMember': (config) => {
    const template = {
      "return_code": 0,
      "result_msg": "Success",
      "data": {
        "floorID": 4
      }
    }
    return [200, Mock.mock(template)]
  },
  '/member/UpdateMember': (config) => {
    const template = {
      "return_code": 0,
      "result_msg": "Success",
      "data": null
    }
    return [200, Mock.mock(template)]
  },
  '/member/DeleteMember': (config) => {
    const template = {
      "return_code": 0,
      "result_msg": "Success",
      "data": null
    }
    return [200, Mock.mock(template)]
  },
  '/memberphoto/AddPhoto': (config) => {
    const template = {
      "return_code": 0,
      "result_msg": "Success",
      "data": {
        "photoID": 1
      }
    }
    return [200, Mock.mock(template)]
  },
  '/memberphoto/UpdatePhoto': (config) => {
    const template = {
      "return_code": 0,
      "result_msg": "Success",
      "data": null
    }
    return [200, Mock.mock(template)]
  },
}

mocks.forEach(mock => {
  for (const api in ApiMap) {
    mock.onAny(new RegExp(`${api}\\b`))
      .reply(ApiMap[api])
  }
})
