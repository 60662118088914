import { $themeBreakpoints } from '@themeConfig'
import i18n from "@/libs/i18n/index";
import { useNetwork } from '@vueuse/core'
import { localStorageApi } from '@/libs/ez-utils'
// import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    showLoading: false,
    loadingNum: 0,
    menuItems: [],
    menuName: {},
    userProfile: {},
    role: {},
    adminCompanyId: undefined,
    adminFacilityId: undefined,
    permissions: [],
    locale: 'zh_HK',
    moduleLabel: {},
    tableCondition: {},
    isImpersonated: false,
    network: useNetwork(),

    toastIdTemp: 0,
    callBellToastNum: 0,
    geoFencingToastNum: 0,

    customConfigs: {},

    // 缓存页面keepAlive
    cachedPages: [],
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    getCustomConfigs(state) {
      return state.customConfigs
    },
    getCustomConfigByKey(state) {
      return (key) => {
        return state.customConfigs[key]
      }
    }
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    TOGGLE_LOADING(state, val) {
      state.showLoading = val
    },
    SET_MENU_ITEMS(state, val) {
      state.menuItems = val
      localStorage.setItem('menu_items', JSON.stringify(val));
    },
    REFRESH_MENU_ITEMS(state) {
      if (localStorage.getItem('menu_items')) {
        state.menuItems = JSON.parse(localStorage.getItem('menu_items'))
      }
    },
    SET_MENU_NAME(state, val) {
      state.menuName = val
      localStorage.setItem('menu_name', JSON.stringify(val));
    },
    REFRESH_MENU_NAME(state) {
      if (localStorage.getItem('menu_name')) {
        state.menuName = JSON.parse(localStorage.getItem('menu_name'))
      }
    },

    SET_USER_PROFILE(state, val) {
      state.userProfile = val
      localStorage.setItem('user_profile', JSON.stringify(val));
    },
    REFRESH_USER_PROFILE(state) {
      if (localStorage.getItem('user_profile')) {
        state.userProfile = JSON.parse(localStorage.getItem('user_profile'))
      }
    },

    SET_ROLE(state, val) {
      state.role = val
      localStorage.setItem('role', JSON.stringify(val))
    },
    REFRESH_ROLE(state) {
      if (localStorage.getItem('role')) {
        state.role = JSON.parse(localStorage.getItem('role'))
      }
    },

    SET_ADMIN_COMPANY_ID(state, val) {
      state.adminCompanyId = val
      localStorage.setItem('admin_company_id', JSON.stringify(val))
    },
    REFRESH_ADMIN_COMPANY_ID(state) {
      if (localStorage.getItem('admin_company_id')) {
        state.adminCompanyId = JSON.parse(localStorage.getItem('admin_company_id'))
      }
    },

    SET_ADMIN_FACILITY_ID(state, val) {
      state.adminFacilityId = val
      localStorage.setItem('admin_facility_id', JSON.stringify(val))
    },
    REFRESH_ADMIN_FACILITY_ID(state) {
      if (localStorage.getItem('admin_facility_id')) {
        state.adminFacilityId = JSON.parse(localStorage.getItem('admin_facility_id'))
      }
    },

    SET_PERMISSIONS(state, val) {
      state.permissions = val
      localStorage.setItem('permission', JSON.stringify(val));
    },
    REFRESH_PERMISSIONS(state) {
      if (localStorage.getItem('permission')) {
        state.permissions = JSON.parse(localStorage.getItem('permission'))
      }
    },

    SET_LOCALE(state, val) {
      state.locale = val
      localStorage.setItem('locale', JSON.stringify(val));
      i18n.locale = val
    },
    REFRESH_LOCALE(state) {
      if (localStorage.getItem('locale')) {
        state.locale = JSON.parse(localStorage.getItem('locale'))
        i18n.locale = state.locale
      }
    },

    SET_MODULE_LABEL(state, val) {
      state.moduleLabel = val
      localStorage.setItem('module_label', JSON.stringify(val));
    },
    REFRESH_MODULE_LABEL(state) {
      const module_label = JSON.parse(localStorage.getItem('module_label'))
      i18n.mergeLocaleMessage(i18n.locale, module_label)
    },

    SET_TABLE_CONDITION(state, val) {
      state.tableCondition = val
    },

    INC_LOADING_NUM(state) {
      state.loadingNum++
    },
    DEC_LOADING_NUM(state) {
      state.loadingNum--
      if (state.loadingNum < 0) {
        state.loadingNum = 0
      }
    },

    SET_IMPERSONATED(state, val) {
      const tmpVal = val ? val : false
      state.isImpersonated = tmpVal
      localStorage.setItem('is_impersonated', JSON.stringify(tmpVal));
    },
    REFRESH_IMPERSONATED(state) {
      if (localStorage.getItem('is_impersonated')) {
        state.isImpersonated = JSON.parse(localStorage.getItem('is_impersonated'))
      }
    },

    INC_TOAST_ID(state) {
      state.toastIdTemp++
    },
    INC_CALL_BELL_TOAST_NUM(state) {
      state.callBellToastNum++
    },
    DEC_CALL_BELL_TOAST_NUM(state) {
      state.callBellToastNum--
      if (state.callBellToastNum < 0) {
        state.callBellToastNum = 0
      }
    },
    INC_GEO_FENCING_TOAST_NUM(state) {
      state.geoFencingToastNum++
    },
    DEC_GEO_FENCING_TOAST_NUM(state) {
      state.geoFencingToastNum--
      if (state.geoFencingToastNum < 0) {
        state.geoFencingToastNum = 0
      }
    },

    REFRESH_CUSTOM_CONFIGS(state) {
      const configs = localStorageApi.getJSON('custom_configs')
      state.customConfigs = configs ? configs : {}
    },
    ASSIGN_CUSTOM_CONFIGS(state, val) {
      const configs = Object.assign(state.customConfigs, val)
      localStorageApi.setJSON('custom_configs', configs)
    },

    ADD_CACHED_PAGE(state, page) {
      if (state.cachedPages.includes(page)) return
      state.cachedPages.push(page)
    },
    DELETE_CACHED_PAGE(state, page) {
      const index = state.cachedPages.findIndex(cP => cP === page);
      state.cachedPages.splice(index, 1)
    }
  },
  actions: {},
}
